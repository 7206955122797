












import {Component, Prop} from 'vue-property-decorator'
import {mixins} from 'vue-class-component'
import {Getter, State} from 'vuex-class'
import VuetifyColorHelper, {CustomColorableMixin} from '@/lib/vuetify/VuetifyColorHelper'
import Utils from '@/utils'
import ChangeLangDialog from '@/views/ChangeLangDialog.vue'

@Component({
  name: 'TopBarPopUp',
  components: {
    Avatar: Utils.loadComponent('proxy/Avatar'),
    Toolbar: Utils.loadComponent('proxy/Toolbar'),
    ToolbarTitle: Utils.loadComponent('proxy/ToolbarTitle'),
    CloseButton: Utils.loadComponent('CloseButton'),
    Img: Utils.loadComponent('proxy/Image'),
  },
  mixins: [CustomColorableMixin],
})
export default class TopBarPopUp extends mixins<CustomColorableMixin>(CustomColorableMixin) {
  @State((state) => state.topbar.topBarTitle) public topBarTitle!: string | null
  @Getter('popupTitle') public popupTitle!: string | null
  @Getter('currentLanguageFlag') public lang?: string
  @Prop({type: Boolean, default: false}) public logo!: boolean
  @Prop({type: Boolean, default: false}) public langSelector!: boolean

  public get title() {
    return this.popupTitle ?? this.topBarTitle ?? null
  }

  protected get gradientAngle() {
    if (this.themeName && VuetifyColorHelper?.gradientAngle('topBar')) {
      return VuetifyColorHelper.gradientAngle(this.themeName)
    }
    return this.angle || null
  }

  protected get customStyle() {
    const arr: Array<Record<string, string>> = []
    const top = this.color('topBarTop') || 'transparent'
    const bottom = this.color('topBarBottom') || 'transparent'
    arr.push({
      'background-image': `linear-gradient(${this.gradientAngle || this.angle}deg, ${top}, ${bottom})`,
    })
    if (this.customCss) {
      arr.push(this.customCss)
    }
    return arr
  }

  protected get isLight() {
    const top = this.color('topBarTop')
    const bottom = this.color('topBarBottom')
    if (this.customClasses?.includes('theme--dark')) {
      return false
    }
    if (this.customClasses?.includes('theme--light')) {
      return true
    }
    if (this.contrastOverride !== null) {
      return this.contrastOverride
    } else if (top && bottom) {
      return VuetifyColorHelper.lightContrast(top, bottom)
    }
    return !this.$vuetify.dark
  }

  protected changeLang() {
    this.$dialog.open(ChangeLangDialog)
  }
}
