




















































































































import {Vue, Component} from 'vue-property-decorator'
import {Action, Getter, State} from 'vuex-class'

import {ProfileState} from '@/store/modules/profile'
import Locate, {Position} from '@/lib/location'
import {
  EnrichedPoiData,
  EVChargingStation,
  FieldConfigs,
  MapMarker,
  ParkingLot,
  PoiData,
  VehicleSlot,
  Zone,
} from '@/lib/kepler/interfaces'
import ServiceMesh from '@/lib/serviceMesh'
import Utils from '@/utils'

import CenterPointIconSVG from '../../public/img/map/markers/map_position_icon.svg?inline'
import NMap from '@/lib/n-maps/src/NMap.vue'
import {EventBus} from '@/main'
import Splash from '@/views/Splash.vue'
import DateHelper from '@/lib/DateHelper'

interface MarkerLabel {
  color?: string
  fontFamily?: string
  fontSize?: string
  fontWeight?: string
  text: string
  origin?: [number, number]
}

@Component({
  components: {
    CenterMapButton: Utils.loadView('Map/CenterMapButton'),
    FiltersContainer: Utils.loadView('Map/FiltersContainer'),
    CarouselContainer: Utils.loadView('Map/CarouselContainer'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Alert: Utils.loadComponent('proxy/Alert'),
    Layout: Utils.loadComponent('proxy/Layout'),
    TopBar: Utils.loadComponent('TopBar'),
    Button: Utils.loadComponent('Button'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
    CenterPoint: Utils.loadComponent('CenterPoint'),
    CircleToggle: Utils.loadComponent('CircleToggle'),
    ProgressCircular: Utils.loadComponent('proxy/ProgressCircular'),
    VehicleCardPopup: Utils.loadComponent('entities/vehicle/VehicleCardPopup'),
    ParkingCardPopup: Utils.loadComponent('entities/vehicle/ParkingCardPopup'),
    MapFiltersWrapper: Utils.loadComponent('filters/MapFiltersWrapper'),
    VehicleSlotAvatar: Utils.loadComponent('VehicleSlotAvatar'),
  },
})
export default class Map extends Vue {
  // Store mappings
  @State('profile') public profileState!: ProfileState
  @State((state) => state.configuration.appConfig?.default_latitude) public default_latitude?: number
  @State((state) => state.configuration.appConfig?.default_longitude) public default_longitude?: number
  @State((state) => state.configuration.appConfig?.fields_configuration?.map_zoom_level) public zoomLevel?: number

  @Getter('popupIsOpen') public popupIsOpen!: number
  @Getter('debugMode') public debugMode!: boolean
  @Getter('fieldConfigs') public fieldConfigs!: FieldConfigs

  @Getter('filteredMapVehicles') public readonly vehicles!: MapMarker[]
  @Getter('filteredMapPoi') public readonly poi!: EnrichedPoiData[]
  @Getter('filteredMapZones') public readonly zones!: Zone[]
  @Getter('filteredMapParkings') public readonly parkings!: ParkingLot[]
  @Getter('filteredEVChargingStations') public readonly evChargingStations!: EVChargingStation[]

  @Action('setUserPosition') public setUserPosition!: (p: Position) => void
  @Action('getEVChargingStations') public getEVChargingStations!: (radius: number) => Promise<void>

  public carouselType: 'hidden' | 'vehicle' | 'parking' | 'poi' | 'evCharging' = 'hidden'
  public carouselElements: VehicleSlot[] = []

  public gpsError: number = 0
  public mapActive = false
  public centerPoint: Position = {
    lat: 0,
    lng: 0,
    acc: null,
    hdg: null,
  }
  public centerPointIcon = CenterPointIconSVG
  public loading: boolean = false
  public loadingCharginStations: boolean = false
  public lastCharginStations: number = 0
  public firstLoad: boolean = true
  public locationWatcher: number | null = null
  public headingWatcher: number | null = null
  public showChargingStations: boolean = false

  protected get mapOn() {
    const r = !this.popupIsOpen && this.mapActive
    if (!r) {
      this.hideCarousel()
    }
    return r
  }

  protected get clusteredPOI() {
    return this.poi.filter((p) => p.visibility === 'normal')
  }

  protected get unclusteredPOI() {
    return this.poi.filter((p) => p.visibility === 'important')
  }

  protected get hasClusterable() {
    const layers = this.vehicles.length > 0
    const park = this.parkings.length > 0
    const poi = Object.keys(this.clusteredPOI).length
    const evcharging = Object.keys(this.evChargingStations).length
    return this.mapOn && (layers || park || poi || evcharging)
  }

  protected get mapRef() {
    return this.$refs.map as NMap | undefined
  }

  protected getPolyLineColor(zone: string) {
    return ServiceMesh.getPolyLineColor(zone)
  }

  protected centerMap() {
    if (this.profileState.userPosition) {
      this.mapRef?.pan(this.profileState.userPosition)
    }
    EventBus.$emit('recenter')
  }

  protected setZoom(level: number) {
    this.mapRef?.zoom(level)
  }

  protected setCenterPoint(p: Partial<Position>) {
    const e = Object.entries(p)
    for (const [k, v] of e) {
      if (typeof v === 'number') {
        this.$set(this.centerPoint, k, v)
      }
    }
  }

  protected cloneObj(o: Record<any, any>) {
    return JSON.parse(JSON.stringify(o))
  }

  protected pleaseKeepLocateCallback(pos: Position | GeolocationPositionError) {
    const defaultCenterPoint: Position = {
      lat: this.default_latitude || 0,
      lng: this.default_longitude || 0,
      acc: null,
    }

    if (pos && !('message' in pos)) {
      if (this.headingWatcher) {
        delete pos.hdg
      }
      this.setCenterPoint(pos)
      this.setUserPosition(pos)
      this.gpsError = 0
    } else {
      this.setCenterPoint(defaultCenterPoint)
      this.setUserPosition(defaultCenterPoint)
      this.gpsError = pos.code
      this.locationWatcher = null
    }
    this.loading = false
    if (this.firstLoad) {
      this.centerMap()
      this.firstLoad = false
    }
  }

  protected setupMap() {
    this.loading = true
    return new Promise((resolve: (value: number) => void) => {
      if (!this.locationWatcher) {
        this.locationWatcher = Locate.please_keep_locate(this.pleaseKeepLocateCallback)
      } else {
        this.loading = false
        this.centerMap()
        return this.locationWatcher
      }
    })
  }

  protected setupHeading() {
    if (!this.headingWatcher) {
      if (device.platform === 'browser' && !this.debugMode) {
        return null
      } else {
        return Locate.getCompassHeading(((pos) => {
          if (pos?.hdg) {
            this.setCenterPoint({hdg: pos.hdg})
          }
        }))
      }
    } else {
      return this.headingWatcher
    }
  }

  protected parkingMarkerLabel(m: any): { label: MarkerLabel } {
    let available = m.parking_slots_availability.available
    available = available || ''
    const color = this.$branding.theme.PARKING ? this.$branding.theme.PARKING.toString() : ''
    return available ? {
      label: {
        text: available.toString(),
        fontSize: '10px',
        fontWeight: '600',
        color,
        origin: [27, 25],
      },
    } : {
      label: {
        text: ' ',
        origin: [15, 15],
      },
    }
  }

  protected initMap() {
    this.$nextTick(() => {
      this.setupMap().then((r) => {
        this.locationWatcher = r
        this.headingWatcher = this.setupHeading()
        this.setZoom(this.zoomLevel ?? 15)
      })
    })
    this.mapRef?.refresh()
  }

  // Lifecycle hooks
  protected created() {
    this.setCenterPoint({
      lat: this.default_latitude || 0,
      lng: this.default_longitude || 0,
    })
  }

  protected mounted() {
    this.initMap()
    this.mapActive = true
  }

  protected activated() {
    if (!this.mapActive) {
      this.initMap()
      this.mapActive = true
    }
  }

  protected deactivated() {
    if (this.locationWatcher) {
      Locate.please_stop_locate(this.locationWatcher)
      this.locationWatcher = null
    }
    if (this.headingWatcher) {
      Locate.stopCompassWatcher(this.headingWatcher)
      this.headingWatcher = null
    }
    this.mapActive = false
  }

  protected keyBy(lat?: string | number, lng?: string | number, id?: string | number) {
    return Utils.hash(String(id) + String(lat) + String(lng))
  }

  protected getLabel(p: PoiData): MarkerLabel | undefined {
    const counter = p.counter.toString()
    const text = (counter !== 'none' ? counter : '') || ' '
    return {
      text,
      fontSize: '10px',
      fontWeight: '600',
      color: 'grey',
      origin: [27, 25],
    }
  }

  protected selectMarker(type: 'hidden' | 'vehicle' | 'parking' | 'poi' | 'evCharging', m: any[], pos: Position) {
    this.carouselType = type
    this.carouselElements = m
    this.$nextTick(() => {
      this.mapRef?.pan(pos)
    })
  }

  protected getZoneColor(c: string | null) {
    switch (c) {
      case 'red':
        return this.$branding.theme.error
      case 'yellow':
        return this.$branding.theme.warning
      case 'green':
        return this.$branding.theme.success
      default:
        return c
    }
  }

  protected mapClick() {
    this.hideCarousel()
  }

  protected hideCarousel() {
    this.carouselType = 'hidden'
  }

  protected openSplash() {
    this.$popup.open(Splash, {hideTopbar: true})
  }

  protected toggleChargingStations() {
    if (!this.showChargingStations) {
      this.loadingCharginStations = true
      const now = DateHelper.getTimestamp()
      if (now - this.lastCharginStations > 10) {
        this.lastCharginStations = DateHelper.getTimestamp()
        const range = this.fieldConfigs.ev_charge_search_range || 50000
        this.getEVChargingStations(range).then(() => {
          this.mapRef?.getBoundsAndPan(this.evChargingStations.map(({lat, lng}) => ({lat, lng})))
        }).finally(() => {
          this.loadingCharginStations = false
          this.showChargingStations = true
        })
      } else {
        this.loadingCharginStations = false
        this.showChargingStations = true
      }
    } else {
      this.showChargingStations = false
    }
  }

  protected chargingStationIcon(ec: EVChargingStation) {
    const av = ec.ports.some((p) => p.status === 'AVAILABLE')
    return av ? 'img/map/markers/EVCHARGE.png' : 'img/map/markers/EVCHARGE.png' // TODO: create unavailable icon
  }
}
