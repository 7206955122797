


























































































import {Component, Vue} from 'vue-property-decorator'
import {Action, Getter, State} from 'vuex-class'

import Utils from '@/utils'
import {ProfileState} from '@/store/modules/profile'
import {AppConfigState} from '@/store/modules/configuration'

import ConfirmDialogCallback from '@/views/ConfirmDialogCallback.vue'
import LoginDialog from '@/views/LoginDialog.vue'
import {Dialog, LoginResponse} from '@/lib/kepler/interfaces'
import {Account} from '@/store/modules/accounts'
import ConfirmDialog from '@/views/ConfirmDialog.vue'
import ChangePasswordDialog from '@/views/ChangePasswordDialog.vue'
import TextareaDialog from '@/views/TextareaDialog.vue'
import sdk from '@/lib/kepler/sdk'
import ChangePinDialog from '@/views/ChangePinDialog.vue'
import {VSpacer} from 'vuetify/lib'
import FlowAutoload from '@/views/Flows/FlowAutoload.vue'
import FlowDialog from '@/views/Flows/FlowDialog.vue'
import {FlowInputsState} from '@/store/modules/flowInputs'

@Component({
  components: {
    ClientDriverStatus: Utils.loadComponent('ClientDriverStatus'),
    List: Utils.loadComponent('proxy/List/List'),
    Img: Utils.loadComponent('proxy/Image'),
    Sheet: Utils.loadComponent('proxy/Sheet'),
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Flex: Utils.loadComponent('proxy/Flex'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    CardText: Utils.loadComponent('proxy/Card/CardText'),
    CardList: Utils.loadComponent('CardList'),
    Button: Utils.loadComponent('Button'),
    FormTag: Utils.loadComponent('proxy/Inputs/FormTag'),
    SelectTag: Utils.loadComponent('proxy/Inputs/SelectTag'),
    CustomIcon: Utils.loadComponent('CustomIcon'),
    Divider: Utils.loadComponent('proxy/Divider'),
    Icon: Utils.loadComponent('proxy/Icon'),
    VSpacer,
  },
})
export default class Settings extends Vue {
  @State('profile') protected profileState!: ProfileState
  @State('configuration') protected configState!: AppConfigState
  @State('flowInputs') protected flowInputs!: FlowInputsState

  @Getter('currentLanguageFlag') protected currentLanguageFlag!: string
  @Getter('showSplash') protected showSplashState!: boolean

  @Action('setMapStyle') protected setMapStyle!: any
  @Action('openDialog') protected openDialog!: (dialog: Dialog) => void
  @Action('setLang') protected setLang!: (lang: string) => void
  @Action('addProfile') protected addProfile!: (token: string) => Promise<void>
  @Action('closeOverlays') protected closeOverlays!: () => void
  @Action('logout') protected logout!: () => void
  @Action('setSplashShow') protected setSplash!: (b: boolean) => void
  @Action('saveAccount') protected saveAccount!: (p?: Account & { id: string }) => Promise<void>

  protected loading: boolean = false
  protected lang: string = ''
  protected eec: number = 0

  protected get supportUrl() {
    return this.configState.appConfig.support_center_url
  }

  protected get testFlow() {
    const f: string | undefined = this.configState.appConfig.fields_configuration.test_flow
    return f ? () => this.openFlow(f) : null
  }

  protected get showSplash() {
    return this.showSplashState
  }

  protected set showSplash(v: boolean) {
    this.setSplash(v)
  }

  protected get hasMultipleLangs() {
    return this.$langAvailable().length > 1
  }

  protected get hasSplash() {
    return !!this.configState.appConfig.splash_configuration?.length
  }

  protected openFlow(flowName?: string) {
    const flow = this.flowInputs[flowName || '']
    if (flowName && flow) {
      if (flow.dialog) {
        this.$dialog.open(FlowDialog, {props: {flowName}})
      } else {
        this.$popup.open(FlowAutoload, {props: {flowName}, hideTopbar: true})
      }
    }
  }

  protected isBrand(name: string) {
    return this.$env.PAGE_TITLE.toLowerCase() === name.toLowerCase()
  }

  protected mounted() {
    this.lang = this.currentLanguageFlag
  }

  protected addAccount() {
    const id = this.profileState.driver?.id
    if (id) {
      this.saveAccount().then(() => {
        this.$dialog.open(LoginDialog, {
          props: {
            leaveOpen: true,
            callback: (r: LoginResponse) => {
              this.loading = true
              this.addProfile(r.token)
                .then(() => {
                  this.closeOverlays()
                  this.$dialog.open(ConfirmDialog, {
                    props: {
                      imageState: 'success.svg',
                      confirmText: this.$t('common.continue'),
                      code: '',
                      subtitle: this.$isAvailable('profile.switch.added.subtitle'),
                      title: this.$t('profile.switch.added.title'),
                      singleAction: true,
                      emitConfirm: false,
                    },
                  })
                })
                .finally(() => {
                  this.loading = false
                })
            },
          }, hideTopbar: true,
        })
      })
    }
  }

  protected requestDeletion() {
    const t = (x: string) => this.$t('profile.settings.delete_account.' + x)
    this.openDialog(new Dialog(TextareaDialog, {
      title: t('title'),
      subtitle: t('subtitle'),
      confirmText: t('confirm'),
      confirmColor: 'error',
      confirmCallback: (text: string) => {
        sdk.profile.request_deletion(text).then(() => {
          this.openDialog(new Dialog(ConfirmDialogCallback, {
            title: t('confirmation.title'),
            subtitle: t('confirmation.subtitle'),
          }))
        })
      },
      cancelCallback: this.$dialog.close,
    }))
  }

  protected saveSettings() {
    this.loading = true
  }

  protected changeLang(code: string) {
    const lang = this.$flagToLang(code)
    this.setLang(lang)
    this.$langLoader(lang)
    this.$vuetify.rtl = ['ar'].includes(lang)
  }

  protected openSupport() {
    cordova.InAppBrowser.open(this.supportUrl, '_system')
  }

  protected openTocs() {
    this.$popup.close()
    this.$router.push({name: 'terms'})
  }

  protected openChangePassword() {
    this.openDialog(new Dialog(ChangePasswordDialog, {}))
  }

  protected openChangePin() {
    this.openDialog(new Dialog(ChangePinDialog, {}))
  }

  protected ee() {
    if (this.eec < 25) {
      this.eec++
    } else {
      this.openDialog(new Dialog(ConfirmDialogCallback, {
        imageState: null,
        code: null,
        title: null,
        subtitle: 'tapping on random numbers doesn\'t make you a developer',
        cancelText: 'oh no',
        confirmText: 'oh yes',
        emitConfirm: false,
        showCloseButton: false,
        confirmColor: 'error',
        confirmCallback: () => {
          this.$router.push('/dev')
        },
      }))
      this.eec = 0
    }
  }
}
