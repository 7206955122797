






















import {Component, Prop} from 'vue-property-decorator'
import {mixins} from 'vue-class-component'
import {Action, Getter, State} from 'vuex-class'
import {TopbarButton} from '@/store/modules/topbar'
import Utils from '@/utils'
import {
  PaginatedRequest,
  ReservationPaginatedResponse,
  ReservationResponse,
} from '@/lib/kepler/interfaces'

import HistoryBookingDetail from './HistoryBookingDetail.vue'
import InfiniteScrollHandler, {infiniteHandlerState} from '../../lib/InfiniteScrollHandler'
import DateHelper from '@/lib/DateHelper'
import ReservationHelper from '@/lib/reservation'

@Component({
  components: {
    Container: Utils.loadComponent('proxy/Container'),
    Layout: Utils.loadComponent('proxy/Layout'),
    ProgressCircular: Utils.loadComponent('proxy/ProgressCircular'),
    CardReservation: Utils.loadComponent('entities/reservation/CardReservation'),
    InfiniteLoading: Utils.loadComponent('infiniteScroll/InfiniteLoading'),
    ProgressLinear: Utils.loadComponent('proxy/ProgressLinear'),
    EmptyList: Utils.loadComponent('EmptyList'),
    LoopingBG: Utils.loadComponent('LoopingBG'),
  },
  mixins: [InfiniteScrollHandler],
})
export default class FullListHistory extends mixins(InfiniteScrollHandler) {
  @State((state) => state.booking.reservations) public reservations!: ReservationPaginatedResponse

  @Getter('activeReservations') public activeReservations!: ReservationResponse[]
  @Getter('futureReservations') public futureReservations!: ReservationResponse[]

  @Action('reservationHistory') public reservationHistory!: (req?: PaginatedRequest) => Promise<ReservationPaginatedResponse>
  @Action('setActionButtons') public setActionButtons!: (buttons: TopbarButton[]) => void
  @Action('flushAndReloadHistory') public flushAndReloadHistory!: () => Promise<any>

  @Prop({
    type: Number,
    default: Infinity,
  }) public max!: number
  @Prop({
    type: Boolean,
    default: true,
  }) public reloadButton!: boolean
  @Prop({
    type: Function || null,
    default: null,
  }) public action!: () => void

  protected loading: boolean = true

  protected get detailAction() {
    if (this.action !== null) {
      return this.action
    }
    return this.openDetail
  }

  protected get futures(): number[] {
    const arr = []
    for (const res of this.futureReservations) {
      if (res.number) {
        arr.push(res.number)
      }
    }
    return arr
  }

  protected mounted() {
    if (this.max !== Infinity) {
      this.flushAndReloadHistory().finally(() => {
        this.loading = false
      })
    } else if (this.reservations.current_page < 0) {
      this.reservationHistory().finally(() => {
        this.loading = false
      })
    } else {
      this.loading = false
    }

    if (this.reloadButton) {
      const buttons: TopbarButton[] = [{
        icon: 'mdi-refresh',
        action: () => {
          this.reloadHistory()
        },
      },
      ]
      this.setActionButtons(buttons)
    }
  }

  protected infiniteHandler(state: infiniteHandlerState) {
    if (this.reservations.current_page >= 0) {
      this.handler(state, this.max, this.reservations, this.reservationHistory)
    }
  }

  protected reloadHistory() {
    this.loading = true
    const infinite = (this.$refs.infiniteLoader as any)
    infinite.isLoading = true
    return this.flushAndReloadHistory().finally(() => {
      this.loading = false
      infinite.isLoading = false
    })
  }

  protected openDetail(reservation: ReservationResponse) {
    const current: boolean = ReservationHelper.isCurrent(reservation)
    const late: boolean = ReservationHelper.isLate(reservation)

    if (current || late) {
      this.openReservationDetail(reservation)
    } else {
      this.openHistoryDetail(reservation)
    }
  }

  protected openReservationDetail(reservation: ReservationResponse) {
    this.$router.push({name: 'reservation', params: {id: reservation.id}})
  }

  protected openHistoryDetail(reservation: ReservationResponse) {
    this.$popup.open(HistoryBookingDetail, {
      props: {resNum: reservation.number},
      title: this.$t('booking.reservation', {number: reservation.number}),
    })
  }

  protected isActive(reservation: ReservationResponse): boolean {
    const active = this.activeReservations.find((activeRes) => {
      reservation.current_vehicle_position = activeRes.current_vehicle_position
      return activeRes.id === reservation.id
    })

    // checks for closed FF reservations, pretty much redundant but meh.
    if (reservation.type === 'FF' && reservation.status === 'CONFIRMED' && !active) {
      if (reservation.end_timestamp && (DateHelper.getTimestamp() - reservation.end_timestamp) < 0) {
        this.reloadHistory().then(() => active)
      }
    }
    // reservation is in active reservations
    return !!active
  }
}
