






import {Component, Vue} from 'vue-property-decorator'
import {Action, Getter, State} from 'vuex-class'
import {EventBus} from '@/main'
import {AppConfig, HomeModeEnum} from '@/lib/kepler/interfaces'
import FlowAutoload from '@/views/Flows/FlowAutoload.vue'
import Loader from '@/components/Loader.vue'

@Component({
  components: {FlowAutoload, Loader},
})
export default class ConfigLoadedView extends Vue {
  @State((state) => state.configuration.homeMode) public homeMode!: HomeModeEnum
  @Getter('currentLanguage') public lang!: string
  @Getter('isOnline') public isOnline!: boolean
  @Action('loadConfig') public loadConfig!: () => Promise<AppConfig>
  @Action('getFlows') public getFlows!: () => Promise<void>

  protected isConfigLoaded: boolean = false

  protected created() {
    EventBus.$off('login', this.resetConfig)
    EventBus.$on('login', this.resetConfig)
    this.init()
  }

  protected async init() {
    if (!this.isOnline) {
      this.isConfigLoaded = true
      return
    }

    if (this.$route.name === 'home') {
      const results = this.lang ?
        await Promise.all([this.loadConfig(), this.getFlows()]) :
        await Promise.all([this.loadConfig()])
      const r = results[0]

      if (!this.lang) {
        this.$langLoader(r.default_language ?? undefined)
      }
      this.isConfigLoaded = true
    } else {
      this.isConfigLoaded = true
    }
  }

  protected beforeDestroy() {
    EventBus.$off('login', this.resetConfig)
  }

  protected resetConfig() {
    this.isConfigLoaded = false
    this.$nextTick(this.init)
  }
}
